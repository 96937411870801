import React from 'react'
import { useParams } from 'react-router-dom'
import { useCQuery } from '../../Services'
import { Flex, Spinner } from '../../Components'
import { useAuth } from '../../Provider/AuthProvider'
import { Text } from '../../Components/Common/Text'

export const Invoice = () => {
  const { fileName } = useParams()
  const { user } = useAuth()
  const decodedFileName = fileName.replace(/encoded/g, '.')
  const { data: invoice, isSuccess: isInvoiceSuccess } = useCQuery(['invoice', user.user.codSociety, decodedFileName])
  const { data: gptResult = {}, isSuccess: isSuccessGptResult } = useCQuery(['gptResult', user.user.codSociety, decodedFileName])

  if (!isInvoiceSuccess || !isSuccessGptResult) return <Spinner />
  return (
    <>
      <Flex fw je as style={{ ...styles }}>
        <Flex fw fh row>
          <Flex fh as style={{ backgroundColor: '#f2f2f2', borderRadius: 20, width: '50%', margin: '0px 10px', height: 'calc(100% - 20px)', padding: '1em' }}>
            <Text value={'Fattura ' + decodedFileName} color='#000' bold title />
            <br />
            <textarea style={textAreaStyle} value={invoice || ''} readOnly />
          </Flex>
          <Flex fh as js style={{ backgroundColor: '#f2f2f2', borderRadius: 20, width: '50%', margin: '0px 10px', height: 'calc(100% - 20px)', padding: '1em' }}>
            <Text value='Informazioni trovate' color='#000' bold title />
            <br />
            <Text value={'Partita IVA: ' + (gptResult?.result?.hotelVAT || 'Non trovata')} color='#000' />
            <Text value={'Data check-in: ' + (gptResult?.result?.checkinDate || 'Non trovata')} color='#000' />
            <br />
            {gptResult?.result?.vouchers?.length
              ? (
                <>
                  <Text value='Elenco vouchers trovati: ' color='#000' />
                  <ul>
                    {gptResult?.result?.vouchers.map(({ documentType, documentYear, documentNumber }, index) =>
                      <li key={index} style={{ color: '#000' }}>
                        <Flex row wrap>
                          <Text value={'Tipo: ' + (documentType || 'Non trovato')} color='#000' style={{ marginRight: '1em' }} />
                          <Text value={'Anno: ' + (documentYear || 'Non trovato')} color='#000' style={{ marginRight: '1em' }} />
                          <Text value={'Numero: ' + (documentNumber || 'Non trovato')} color='#000' />
                        </Flex>
                      </li>)}
                  </ul>
                </>)
              : <Text value='Nessun voucher trovato' color='#000' />}
            {gptResult?.result?.passengers?.length
              ? (
                <>
                  <Text value='Elenco passeggeri trovati: ' color='#000' />
                  <ul>
                    {gptResult?.result?.passengers.map(({ name, lastName }, index) =>
                      <li key={index} style={{ color: '#000' }}>
                        <Flex row wrap>
                          <Text value={'Nome: ' + (name || 'Non trovato')} color='#000' style={{ marginRight: '1em' }} />
                          <Text value={'Cognome: ' + (lastName || 'Non trovato')} color='#000' style={{ marginRight: '1em' }} />
                        </Flex>
                      </li>)}
                  </ul>
                </>)
              : <Text value='Nessun passeggero trovato' color='#000' />}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
const styles = {
  backgroundColor: 'rgba(231, 231, 231, 0.8)',
  borderRadius: 20,
  margin: '0 auto',
  height: 'calc(100vh - 170px)',
  backdropFilter: 'blur(4px) saturate(180%)',
  WebkitBackdropFilter: 'blur(4px) saturate(180%)',
  boxShadow: '10px 10px 20px 0px  rgba(100, 100, 100, 0.24)',
  gap: '5%'
}

const textAreaStyle = {
  cursor: 'default',
  borderRadius: 20,
  resize: 'none',
  width: '98%',
  height: '100%',
  color: '#000',
  backgroundColor: '#f2f2f2',
  overflowY: 'scroll',
  border: 'none'
}
