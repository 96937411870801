import React, { useState } from 'react'
import { useCQuery } from '../../Services'
import { Button, Flex, Spinner } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate } from 'react-router-dom'
import { columnProps, tabStyle } from './Functions'
import { Input } from '../../Components/Common/Input'
import { useAuth } from '../../Provider/AuthProvider'

export const ReportsList = () => {
  const [date, setDate] = useState(new Date())
  const { data: invoices = [], isSuccess } = useCQuery(['reports', date])
  const navigate = useNavigate()
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  const { role } = user || {}

  const BtnActions = ({ id, ind: { rowIndex } }) => (
    <Flex row>
      {role === 'admin' &&
        <Button round tooltip='Dettagli' icon='right' onClick={() => navigate(`/reports/${id}`)} />}
    </Flex>
  )

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw>
      <Flex row>
        <Button round icon='left' onClick={() => setDate(new Date(date.setDate(date.getDate() - 1)))} style={{ marginRight: 10 }} allowMultipleClick />
        <Input date value={date} id='date' label='Data' onChange={({ date }) => setDate(date)} style={{ border: '1px solid #ccc', width: 300 }} />
        <Button round icon='right' onClick={() => setDate(new Date(date.setDate(date.getDate() + 1)))} style={{ marginLeft: 10 }} allowMultipleClick />
      </Flex>
      <DataTable
        value={invoices} className='TableSignatures' rows={10} filterDisplay='row' responsiveLayout='scroll'
        paginator emptyMessage='Nessun report trovato' tableStyle={tabStyle} header='Report Processi'
      >
        <Column field='codSociety' {...columnProps('Codice Società')} />
        <Column field='totalInvoices' {...columnProps('Totale Fatture')} />
        <Column field='nokInvoices' {...columnProps('Fatture passate in NOK')} />
        <Column field='percentageNok' {...columnProps('Percentuale NOK')} body={(props) => props?.percentageNok?.toFixed(2) + '%'} />
        <Column
          field='createdAt' {...columnProps('Data inizio')}
          body={(props) => props.createdAt && new Date(props.createdAt).toLocaleString()}
        />
        <Column
          field='endAt' {...columnProps('Data fine')}
          body={(props) => props.endAt ? new Date(props.endAt).toLocaleString() : 'In corso'}
        />
        <Column style={{ width: 150 }} body={(props, ind) => <BtnActions {...props} ind={ind} />} />
      </DataTable>
    </Flex>
  )
}
