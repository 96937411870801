export const columnProps = (filterPlaceholder) => ({
  header: filterPlaceholder,
  headerStyle: { visibility: 'hidden' },
  filter: true,
  filterMatchMode: 'contains',
  filterPlaceholder,
  style: { textAlign: 'center', border: 'none' },
  showFilterMenu: false,
  showClearButton: false
})

export const tabStyle = {
  minWidth: '80vw',
  borderRadius: '12px',
  border: '1px solid transparent'
}
