import { useLocation } from 'react-router-dom'
// import giginoPaneVino from '../Resources/giginoPaneVino.png'
const pathMap = [
  { path: '/login', label: 'Login' },
  { path: '/users', label: 'Utenti' }
]

export const usePageTitle = () => {
  useLocation()
  if (window.location.pathname === '/') return 'Home'

  const matchTitle = ({ path }) => path === window.location.pathname

  return pathMap.find(matchTitle)?.label || 'Home'
}

export const useMenuItems = ({ role }) => ([
  { label: 'Processa', icon: 'experiments', path: '/process', role: ['admin'] },
  { label: 'Elenco Report', icon: 'print', path: '/reports', role: ['admin', 'viewer'] },
  { label: 'Elenco Utenti', icon: 'account', path: '/users/list', role: ['admin'] },
  { label: 'Logout', icon: 'primeicon pi-power-off', path: 'logout' }
]).filter(item => !item.role || item?.role?.includes(role))
