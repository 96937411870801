import { useNavigate, useParams } from 'react-router-dom'
import { saveUser, useUser } from './Functions'
import { Spinner, Input, Flex, Text, Button } from '../../../Components'

const defaultStyle = { width: '31%', marginRight: '1%', marginLeft: '1%', marginBottom: 15 }
const fields = {
  username: { label: 'Username' },
  password: { label: 'Password' },
  codSociety: { label: 'Cod Società' },
  atlanteUrl: { label: 'Atlante Url' },
  cron: { label: 'Cron abilitato', optionsList: [{ label: 'Si', value: 1 }, { label: 'No', value: 0 }], select: true }
}

export function UserEditor () {
  const { userId } = useParams()
  const navigate = useNavigate()
  const [user, setUser, isSuccess] = useUser(userId)
  const { id = '' } = user || {}

  const save = async () => {
    const res = await saveUser(user)
    res?.id && navigate('/users/list')
    return res
  }

  if (!isSuccess || !user) return <Spinner />

  const isNew = id === 'new'

  const elaborateInput = () => {
    return Object.keys(fields).map((key) => {
      const { select, optionsList, label } = fields[key] || {}
      if (select) {
        return (
          <Input
            id={key} dropdown label={label}
            options={optionsList}
            value={user[key]}
            key={key}
            onChange={(data) => setUser(key, data[key])}
            style={{ ...defaultStyle }}
          />
        )
      }
      return <Input key={key} value={user[key]} onChange={({ [key]: value }) => setUser(key, value)} label={label} id={key} style={{ ...defaultStyle }} />
    })
  }
  return (
    <Flex fw>
      <Text value={`${isNew ? 'Crea' : 'Modifica'} Utente`} title bold size={30} />
      <Flex fw row wrap style={{ marginTop: 20 }}>
        {elaborateInput()}
        <Flex fw row style={{ marginTop: 20 }}>
          <Button label='Salva' icon='check' style={{ marginRight: 10 }} onClick={save} />
          <Button label='Torna indietro' icon='left' onClick={() => navigate('/users/list')} />
        </Flex>
      </Flex>
    </Flex>
  )
}
