import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { FC } from '.'
import { queryClient } from '../App'
import { useEffect } from 'react'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes,
    refetchInterval: 10 * time.seconds
  }
}

const queryNotFound = {
  config: {},
  queryFn: (key) => { throw new Error('Query Not Found: ' + key) }
}

const queries = {
  invoice: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [, codSociety, fileXML] = [] }) => FC.service('info').get('getInvoice', { query: { codSociety, fileXML } })
  },
  invoices: {
    config: queryConfig.rare,
    queryFn: () => FC.service('invoices').get('find')
  },
  reports: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [, date] = [] }) => FC.service('reports').find({ query: { createdAt: { $gte: date.setHours(0, 0, 0, 0), $lt: date.setHours(23, 59, 59, 999) } } })
  },
  users: {
    config: queryConfig.rare,
    queryFn: () => FC.service('users').find()
  },
  user: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return { id: 'new' }
      const res = await FC.service('users').get(id)
      return res
    }
  },
  report: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [, reportId] = [] }) => FC.service('reports').find({ query: { id: reportId } })
  },
  gptResult: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [, codSociety, fileName] = [] }) => {
      const [res] = await FC.service('gptResults').find({ query: { fileName, codSociety } })
      return res
    }
  },
  getStats: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [, startDate, endDate] = [] }) => FC.service('info').get('getStats', { query: { startDate, endDate } })
  }
}

export const useCQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config } = { ...queries, ...extraQueries }?.[queryKey[0]] || queryNotFound
  return useQuery({ queryKey, queryFn, ...config })
}

export const prefetchQuery = (queryKey, extraQueries = []) => {
  const { queryFn, config } = { ...queries, ...extraQueries }[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  queryClient.prefetchQuery({ queryKey, queryFn, config })
}

export const invalidateQuery = (queryKeys) =>
  (Array.isArray(queryKeys) ? queryKeys : [queryKeys])
    .forEach(queryKey => queryClient.invalidateQueries({ queryKey: [queryKey] }))

// INFINITE QUERIES
const infiniteQueries = {

}

export const useCInfiniteQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config, firstPageLength = 50, pageLength = 50 } = { ...infiniteQueries, ...extraQueries }?.[queryKey[0]] || queryNotFound

  const { data = {}, isSuccess, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage, isStale } = useInfiniteQuery({
    queryKey,
    queryFn: queryFn(firstPageLength, pageLength),
    // devo fetchare nuova pagina se sono della lunghezza massima stabilita (firstPageLength o pageLength)
    getNextPageParam: (lastPage, pages) =>
      (pages?.length === 1)
        ? ((Array.isArray(lastPage) ? lastPage?.length : lastPage?.data?.length) === firstPageLength) ? pages?.length + 1 : null
        : ((Array.isArray(lastPage) ? lastPage?.length : lastPage?.data?.length) === pageLength) ? pages?.length + 1 : null,
    ...config
  })

  // controllo se la pagina è un array o un oggetto con data e ritorno un array di pagine.
  // perchè se è definita la paginazione sul server ritorna un oggetto con data e se non è definita ritorna un array
  const pages = data?.pages?.map(page => Array.isArray(page) ? page : page.data).flat()

  useEffect(() => { !isFetching && !isFetchingNextPage && hasNextPage && fetchNextPage() }, [isFetchingNextPage, isFetching])

  return { data: pages, isSuccess, isFetchingNextPage, isStale }
}
