import React, { useState } from 'react'
import { Outlet, RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ProtectedRoute, Login, ErrorHandler } from './Pages'
import AuthProvider, { useAuth } from './Provider/AuthProvider'
import { FC } from './Services'
import { Flex, Spinner, Background } from './Components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { cap } from './State'
import Dashboard from './Pages/Dashboard/Dashboard'
import { Invoice } from './Pages/Invoices/Invoice'
import { ReportsList } from './Pages/Reports/List'
import { Report } from './Pages/Reports/Report'
import { UsersList } from './Pages/Users/List'
import { UserEditor } from './Pages/Users/Editor'

export const queryClient = new QueryClient()

const LightTheme = React.lazy(() => import('./Themes/Light/Theme'))
const DarkTheme = React.lazy(() => import('./Themes/Dark/Theme'))

const ThemeSelector = ({ children }) => {
  const [darkMode] = cap.darkMode.useState()
  return (
    <>
      <React.Suspense fallback={<></>}>
        {darkMode ? <DarkTheme /> : <LightTheme />}
      </React.Suspense>
      <div className={`theme-${darkMode ? 'dark' : 'light'}`}>
        {children}
      </div>
    </>
  )
}

function AppSetup () {
  const { user: storedUser, onLoginJWT } = useAuth()
  const [loading, setLoading] = useState(true)

  useMount(async () => {
    if (FC.authenticated && !storedUser?.accessToken) {
      setLoading(false)
      return true
    }
    storedUser?.accessToken && await onLoginJWT(storedUser?.accessToken)
    setLoading(false)
  })

  return (
    <ThemeSelector>
      <Background whiteBackground>
        <ReactQueryDevtools initialIsOpen={false} />
        {loading ? <Flex fw fh><Spinner /></Flex> : <Outlet />}
      </Background>
    </ThemeSelector>
  )
}

const CheckRoute = ({ children, role = [] }) => {
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  if (role?.length && !role.includes(user?.role)) {
    return <Navigate to='/reports' />
  }
  return children
}

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorHandler />,
    children: [
      {
        element: <AppSetup />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              { path: '*', index: true, element: <Navigate to='/process' /> },
              { path: '/process', element: <CheckRoute role={['admin']}> <Dashboard /> </CheckRoute> },
              { path: '/users/list', element: <CheckRoute role={['admin']}> <UsersList /> </CheckRoute> },
              { path: '/users/:userId', element: <CheckRoute role={['admin']}> <UserEditor /> </CheckRoute> },
              { path: '/invoices/:fileName', element: <CheckRoute role={['admin']}> <Invoice /> </CheckRoute> },
              { path: '/reports', element: <CheckRoute role={['admin', 'viewer']}> <ReportsList /> </CheckRoute> },
              { path: '/reports/:reportId', element: <CheckRoute role={['admin', 'viewer']}> <Report /> </CheckRoute> }
            ]
          },
          { path: '/login', element: <Login /> }
        ]
      }
    ]
  }
])
function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}
export default App
