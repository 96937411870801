import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useCQuery } from '../../Services'
import { Flex, Spinner } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from '../../Components/Common/Button'
import { tabStyle } from './Functions'
import { Input } from '../../Components/Common/Input'
import { cap } from '../../State'
import { Text } from '../../Components/Common/Text'

const columnProps = (filterPlaceholder) => ({
  header: filterPlaceholder,
  headerStyle: { visibility: 'hidden' },
  filter: true,
  filterMatchMode: 'contains',
  filterPlaceholder,
  style: { textAlign: 'center', border: 'none' },
  showFilterMenu: false,
  showClearButton: false
})

export const Report = () => {
  const { reportId } = useParams()
  const { data: [report] = [], isSuccess: isInvoiceSuccess } = useCQuery(['report', reportId])
  const navigate = useNavigate()
  const [filterReport, setFilterReport] = cap.filterReport.useState()

  const BtnActions = ({ fileName, ind: { rowIndex } }) => (
    <Flex style={{ width: 150 }}>
      <Button
        round tooltip='Visualizza XML' icon='right' onClick={(e) =>
          e.button === 1
            ? window.open(`/invoices/${encodeURIComponent(fileName.replace(/\./g, 'encoded'))}`)
            : navigate(`/invoices/${encodeURIComponent(fileName.replace(/\./g, 'encoded'))}`)}
      />
    </Flex>
  )

  if (!isInvoiceSuccess) return <Spinner />
  return (
    <Flex fw>
      <Text value='Elenco fatture elaborate' size={20} style={{ marginBottom: 20 }} />
      <Flex fw row as js style={{ marginLeft: 30, zIndex: 1 }}>
        <Input
          dropdown label='Stato input' value={filterReport.inputStatus} id='inputFilter'
          onChange={({ inputFilter }) => setFilterReport({ ...filterReport, inputStatus: inputFilter })}
          options={['ATT', 'NOK', 'NSP', 'NKO']} style={{ marginRight: 10, width: 400 }}
        />
        <Input
          dropdown label='Stato output' value={filterReport.outputStatus} id='outputFilter'
          onChange={({ outputFilter }) => setFilterReport({ ...filterReport, outputStatus: outputFilter })}
          options={['NOK', 'NSP', 'NKO']} style={{ width: 400 }}
        />
      </Flex>
      <DataTable
        value={report.elaboratedInvoices?.filter(({ inputStatus, outputStatus }) => {
          if (filterReport.inputStatus && inputStatus !== filterReport.inputStatus) return false
          if (filterReport.outputStatus && outputStatus !== filterReport.outputStatus) return false
          return true
        })}
        className='TableSignatures' rows={10} filterDisplay='row' responsiveLayout='scroll'
        paginator tableStyle={tabStyle} style={{ width: '100%', marginTop: -60 }}
      >
        <Column field='fileName' {...columnProps('Nome file')} />
        <Column field='error' {...columnProps('Errore')} body={(props) => props.error ? JSON.stringify(props.error) : ''} />
        <Column field='inputStatus' {...columnProps('Stato input')} />
        <Column
          {...columnProps('Metodo di controllo')} body={(props) => {
            if (props.outputStatus !== 'NOK') return ''
            return props.voucherNumberCheck ? 'Numero voucher' : props.passengerCheck ? 'Passeggeri + Data' : ''
          }}
        />
        <Column field='outputStatus' {...columnProps('Stato output')} />
        <Column style={{ width: 150 }} body={(props, ind) => <BtnActions {...props} ind={ind} />} />
      </DataTable>
    </Flex>
  )
}
